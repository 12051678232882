<template>
  <div class="bg-secondary text-white text-center">
    <div class="pt-4 pb-2">{{ $t('footer.text') }}</div>
    <b-button v-b-modal.modal-newsletter variant="primary" class="mb-4">{{ $t('footer.newsletter.button') }}</b-button>
    <b-modal id="modal-newsletter" :title="$t('footer.newsletter.title')" size="xl" centered hide-footer>
      <b-form-group id="newsletter-group-1" :label="$t('footer.newsletter.email')" label-for="newsletter-1">
        <b-form-input id="newsletter-1" v-model="newsletter.email" type="email" required></b-form-input>
      </b-form-group>
      <b-form-group id="newsletter-group-2" :label="$t('footer.newsletter.name')" label-for="newsletter-2">
        <b-form-input id="newsletter-2" v-model="newsletter.name" required></b-form-input>
      </b-form-group>
      <b-button variant="primary" v-on:click="submitnewsletter()">{{ $t('forms.submit') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        newsletter: {
          email: '',
          name: ''
        },
        result: null
      }
    },
    methods: {
      submitnewsletter() {
        fetch("https://platformmail.toekomstperspectief.be/api/v1/websitenewsletter.php?email=" + this.newsletter.email + "&firstname=" + this.newsletter.name, {
          "method": "GET",
        })
        .then(response => {
          if(response.ok){
            return response.json()
          }
        })
        .then(response => {
          this.result = response.body;
          this.newsletter.email = '';
          this.newsletter.name = '';
        })
      }
    }
  }
</script>
