<template>
  <div class="home">
    <b-jumbotron class="text-center home-hero" border-variant="white">
      <template #header>
        <img class="img-fluid img-hero" src="@/assets/toekomstperspectief.svg" alt="Toekomstperspectief logo">
      </template>
      <template #lead>
        <div v-html="$t('home.introduction')"></div>
      </template>
    </b-jumbotron>
    <b-card class="text-center mb-4" border-variant="white">
      <img class="img-fluid img-hero" src="@/assets/logodzp.png" alt="De Zeeparel logo">
      <p>{{ $t('home.dezeeparel.text') }}</p>
    </b-card>
    <b-card class="text-center mb-4" border-variant="white">
      <h2>{{ $t('home.modules.title') }}</h2>
    </b-card>
    <b-card-group deck>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-users-class" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.matrix.title') }}</h3>
        <!--<p>{{ $t('home.modules.matrix.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-matrix variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-user-tag" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.ihp.title') }}</h3>
        <!--<p>{{ $t('home.modules.ihp.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-ihp variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-users" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.gwp.title') }}</h3>
        <!--<p>{{ $t('home.modules.gwp.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-gwp variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
    </b-card-group>
    <br>
    <b-card-group deck>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-award" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.competence.title') }}</h3>
        <!--<p>{{ $t('home.modules.competence.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-competence variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-folders" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.file.title') }}</h3>
        <!--<p>{{ $t('home.modules.file.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-file variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
      <b-card class="text-center" footer-bg-variant="white" footer-border-variant="white" border-variant="white">
        <font-awesome-icon icon="calendar-check" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.modules.agenda.title') }}</h3>
        <!--<p>{{ $t('home.modules.agenda.text') }}</p>
        <template #footer>
          <b-button v-b-modal.modal-agenda variant="primary">{{ $t('readmore') }}</b-button>
        </template>-->
      </b-card>
    </b-card-group>
    <br>
    <b-card class="text-center mb-4" border-variant="white">
      <h2>{{ $t('home.integrations.title') }}</h2>
    </b-card>
    <b-card-group deck>
      <b-card class="text-center" border-variant="white">
        <img class="img-fluid googlelogo" src="@/assets/google.svg" alt="Google logo">
        <h3>{{ $t('home.integrations.googlelogin.title') }}</h3>
        <p>{{ $t('home.integrations.googlelogin.text') }}</p>
      </b-card>
      <b-card class="text-center" border-variant="white">
        <img class="img-fluid smartschoollogo" src="@/assets/smartschool.svg" alt="Smartschool logo">
        <h3>{{ $t('home.integrations.smartschoolimport.title') }}</h3>
        <p>{{ $t('home.integrations.smartschoolimport.text') }}</p>
      </b-card>
      <b-card class="text-center" border-variant="white">
        <img class="img-fluid smartschoollogo" src="@/assets/smartschool.svg" alt="Smartschool logo">
        <h3>{{ $t('home.integrations.smartschoollogin.title') }}</h3>
        <p>{{ $t('home.integrations.smartschoollogin.text') }}</p>
      </b-card>
    </b-card-group>
    <br>
    <b-card class="text-center mb-4" border-variant="white">
      <h2>{{ $t('home.security.title') }}</h2>
    </b-card>
    <b-card-group deck>
      <b-card class="text-center" border-variant="white">
        <font-awesome-icon icon="shield-check" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.security.tls.title') }}</h3>
        <p>{{ $t('home.security.tls.text') }}</p>
      </b-card>
      <b-card class="text-center" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-clone" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.security.backups.title') }}</h3>
        <p>{{ $t('home.security.backups.text') }}</p>
      </b-card>
      <b-card class="text-center" border-variant="white">
        <font-awesome-icon icon="fa-duotone fa-building-shield" class="fa-10x text-primary mb-2" style="--fa-primary-color: #EEA02F; --fa-secondary-color: #f7b733; --fa-secondary-opacity: 1"></font-awesome-icon>
        <h3>{{ $t('home.security.hosting.title') }}</h3>
        <p>{{ $t('home.security.hosting.text') }}</p>
      </b-card>
    </b-card-group>
    <b-modal id="modal-matrix" :title="$t('home.modules.matrix.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.matrix.modal.text') }}</p>
    </b-modal>
    <b-modal id="modal-ihp" :title="$t('home.modules.ihp.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.ihp.modal.text') }}</p>
    </b-modal>
    <b-modal id="modal-gwp" :title="$t('home.modules.gwp.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.gwp.modal.text') }}</p>
    </b-modal>
    <b-modal id="modal-competence" :title="$t('home.modules.competence.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.competence.modal.text') }}</p>
    </b-modal>
    <b-modal id="modal-file" :title="$t('home.modules.file.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.file.modal.text') }}</p>
    </b-modal>
    <b-modal id="modal-agenda" :title="$t('home.modules.agenda.modal.title')" size="xl" centered hide-footer>
      <p>{{ $t('home.modules.agenda.modal.text') }}</p>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        newsletter: {
          email: '',
          name: ''
        },
        result: null
      }
    },
    methods: {
      submitnewsletter() {
        fetch("https://platformmail.toekomstperspectief.be/api/v1/websitenewsletter.php?email=" + this.newsletter.email + "&firstname=" + this.newsletter.name, {
          "method": "GET",
        })
        .then(response => {
          if(response.ok){
            return response.json()
          }
        })
        .then(response => {
          this.result = response.body;
          this.newsletter.email = '';
          this.newsletter.name = '';
        })
      }
    }
  }
</script>

<style>
.home-hero {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.img-hero {
  max-height: 150px
}

.smartschoollogo {
  max-width: 200px;
  max-height: 160px;
}

.googlelogo {
  max-width: 200px;
  max-height: 160px;
  width: 100%; height: auto;
}
.card-footer {
  padding-top: 0rem;
}
</style>
