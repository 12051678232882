import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUsers, faUserTag, faUsersClass, faFolders, faAward, faClone, faBuildingShield } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDoubleDown, faCalendarCheck, faShieldCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add( faAngleDoubleDown, faUserTag, faUsersClass, faFolders, faAward, faUsers, faCalendarCheck, faShieldCheck, faClone, faBuildingShield  )

Vue.component('font-awesome-icon', FontAwesomeIcon)

import './app.scss'
import router from './router'
import i18n from './i18n'

Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
